<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <div class="table-filters">
          <b-input-group class="mb-9">
            <b-form-select
              size="sm"
              v-model="filterMyDevices"
              v-if="user.grants.includes('SYSTEM:ADMIN')"
            >
              <option :value="true">My devices</option>
              <option :value="false">{{ $t("COMMON.ALL_DEVICES") }}</option>
            </b-form-select>

            <b-form-input
              size="sm"
              :aria-label="$t('COMMON.NAME')"
              :placeholder="$t('COMMON.NAME')"
              v-model="filterName"
              trim
            ></b-form-input>

            <CompactSuggestion
              suggestionProcessor="deviceTypeSuggestionProcessor"
              :placeholder="$t('DEVICE.DEVICE_TYPE')"
              ref="deviceTypeSuggestionRef"
              size="sm"
              v-model="filterDeviceTypeId"
              style="min-width: 35%"
            ></CompactSuggestion>

            <b-input-group-append>
              <b-button
                size="sm"
                text="Search"
                variant="success"
                @click="onFilterSearch"
                >{{ $t("COMMON.SEARCH") }}</b-button
              >
              <b-button
                size="sm"
                text="Search"
                variant="secondary"
                @click="onFiltersClear"
                >{{ $t("COMMON.CLEAR") }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>

        <b-table
          id="table"
          striped
          bordered
          :items="response.data"
          @sort-changed="sortingChanged"
          no-local-sorting
          responsive
          selectable
          select-mode="single"
          :fields="fields"
          @row-selected="onRowsSelected"
        ></b-table>

        <b-dropdown
          id="dropdown-show-by"
          :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage"
          class="m-md-2"
          offset="-50"
          style="float: right"
        >
          <b-dropdown-item
            v-for="count in showPerPageArray"
            :key="count"
            @click="showPerPageChanged(count)"
            >{{ count }}</b-dropdown-item
          >
        </b-dropdown>

        <div
          v-bind:style="
            response.data.length === 0 || itemsTotal < perPage
              ? 'display:none;'
              : null
          "
        >
          <b-pagination
            v-model="currentPage"
            :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null"
            :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate"
            :size="!calculate ? 'lg' : null"
            :per-page="perPage"
            @change="onPageSelected"
            :total-rows="itemsTotal"
            aria-controls="table"
          >
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>
    <b-modal
      id="modal-delete-confirmation"
      :title="$t('COMMON.CONFIRMATION')"
      @ok="onDelete"
    >
      <p class="my-4" v-if="selectedItem">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedItem.name,
          })
        }}
      </p>
    </b-modal>

    <b-modal
      size="xl"
      id="modal-import"
      :title="$t('DEVICE.IMPORT_DEVICE_DATA')"
      @ok="importData"
      :ok-disabled="$v.importForm.$invalid"
      :cancel-title="$t('COMMON.CANCEL')"
      body-class="position-static"
    >
      <b-overlay
        :show="importDataModalOverlay"
        :rounded="true"
        no-wrap
      ></b-overlay>
      <b-card-body>
        <b-form-group
          id="field-imei-gr"
          label-cols-lg="2"
          :label="$t('DEVICE.IMEI')"
          label-for="field-imei"
        >
          <b-form-input
            id="field-imei"
            v-model="$v.importForm.imei.$model"
            :state="!$v.importForm.imei.$invalid"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="field-password-gr"
          label-cols-lg="2"
          :label="$t('AUTH.INPUT.PASSWORD')"
          label-for="field-password"
        >
          <b-form-input
            id="field-password"
            v-model="importForm.password"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="field-type-gr"
          label-cols-lg="2"
          :label="$t('COMMON.TYPE')"
          label-for="field-type"
        >
          <b-form-select
            v-model="importForm.type"
            id="field-type"
            :options="importTypes"
          ></b-form-select>
        </b-form-group>

        <b-form-group
          id="field-file-gr"
          label-cols-lg="2"
          :label="$t('COMMON.FILE')"
          label-for="field-file"
        >
          <b-form-file
            id="field-file"
            v-model="$v.importForm.file.$model"
            :state="!$v.importForm.file.$invalid"
            :placeholder="$t('COMMON.CHOOSE_FILE_OR_DROP')"
            :drop-placeholder="$t('COMMON.DROP_FILE_HERE')"
            :browse-text="$t('COMMON.BROWSE_FILE')"
          ></b-form-file>
        </b-form-group>
      </b-card-body>
    </b-modal>

    <b-modal
      id="modal-command"
      :title="$t('DEVICE.EXECUTE_DEVICE_COMMAND')"
      @ok="executeCommand"
      :ok-disabled="selectedCommand.value == null"
      size="xl"
      static
      body-class="position-static"
    >
      <b-overlay
        :show="executeCommandOverlay"
        :rounded="true"
        no-wrap
      ></b-overlay>
      <b-card-body>
        <b-form-group
          label-cols-lg="2"
          :label="$t('COMMON.DEVICE')"
          label-for="device"
        >
          <CompactSuggestion
            v-model="selectedDevice.id"
            :disabled="!!selectedCatalogue.id"
            @hit="(val) => setDevice(val.id, val.name, val.tags.typeId)"
            suggestion-processor="deviceSuggestionProcessor"
            :placeholder="$t('COMMON.TYPE_DEVICE')"
            ref="sendCommandDeviceSuggestionProcessor"
            size="sm"
          >
          </CompactSuggestion>
        </b-form-group>

        <b-form-group
          label-cols-lg="2"
          :label="$t('COMMON.CATALOGUE')"
          label-for="catalogue"
        >
          <CompactSuggestion
            ref="sendCommandCatalogueSuggestionProcessor"
            v-model="selectedCatalogue.id"
            :disabled="!!selectedDevice.id"
            @hit="onCatalogueSelected"
            :placeholder="$t('COMMON.TYPE_CATALOGUE')"
            size="sm"
            suggestion-processor="catalogueSuggestionProcessor"
          >
          </CompactSuggestion>
        </b-form-group>

        <b-form-group
          id="field-command"
          label-cols-lg="2"
          :label="$t('COMMON.COMMAND')"
          label-for="field-command"
        >
          <b-form-select
            v-model="selectedCommand.value"
            :options="commands"
            v-on:change="onCommandSelected"
          >
            <template v-slot:first>
              <option :value="null">{{ $t("DEVICE.SELECT_COMMAND") }}</option>
            </template>
          </b-form-select>
        </b-form-group>

        <template v-for="(item, i) in selectedCommand.parameters">
          <b-form-group
            v-bind:key="i"
            id="field-password-gr"
            label-cols-lg="2"
            v-bind:label="item.name"
            label-for="field-p2"
          >
            <b-form-input
              id="field-p2"
              v-model="item.defaultValue"
            ></b-form-input>
          </b-form-group>
        </template>
      </b-card-body>
    </b-modal>
    <b-modal
      id="modal-export"
      :title="$t('DASHBOARD.WIDGET.EXPORT_CSV')"
      @ok="getCSV"
      :ok-disabled="modalCSVDisabled"
      size="xl"
      static
      body-class="position-static"
    >
      <b-overlay :show="csvOverlay" :rounded="true" no-wrap></b-overlay>
      <b-card-body>
        <b-form-group
          label-cols-lg="2"
          :label="$t('COMMON.DEVICE')"
          label-for="device"
        >
          <CompactSuggestion
            v-model="csvDevice.id"
            suggestion-processor="deviceSuggestionProcessor"
            :placeholder="$t('COMMON.DEVICE')"
            ref="csvDeviceSuggestionProcessor"
            size="sm"
          >
          </CompactSuggestion>
        </b-form-group>
      <b-form-group :label="$t('WIDGET.PARAMETERS')">
        <b-form-checkbox-group v-model="csvDevice.parameters" label-cols-lg="2">
          <b-form-checkbox
            class="col-lg-4 col-sm-12"
            v-for="option in variantParameters"
            :key="option.id"
            :value="option.id"
            name="parameter"
            >{{ option.name }} ({{ option.code }})
          </b-form-checkbox>
          <br />
        </b-form-checkbox-group>
      </b-form-group>
      <b-form-group label-cols-lg="2" :label="$t('DASHBOARD.DATE_RANGE')">
        <range-date-picker
          :start-date.sync="csvDevice.startDate"
          :end-date.sync="csvDevice.endDate"
          with-time
        ></range-date-picker>
      </b-form-group>
      <b-form-group label-cols-lg="2" :label="$t('API.GROUP_TYPE')">
        <b-form-select v-model="csvDevice.groupType">
          <option v-for="opt in groupTypeOptions" :key="opt" :value="opt">
            {{ opt }}
          </option>
        </b-form-select>
      </b-form-group>
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Common from "../../common";
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
import axios from "axios";
import CompactSuggestion from "../component/CompactSuggestion";
import RangeDatePicker from "../component/RangeDatePicker";
import { GroupTypeOptions } from "@/common/config";
import { saveAs } from "file-saver";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "devices",
  components: { CompactSuggestion, RangeDatePicker },
  mixins: [validationMixin],
  validations: {
    importForm: {
      imei: {
        required,
      },
      file: {
        required,
      },
    },
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          formatter: Common.idObjectFormatter,
          sortingKey: "el.id",
          sortable: false,
        },
        {
          key: "created",
          label: this.$t("COMMON.CREATED"),
          formatter: Common.idObjectFormatter,
          sortingKey: "el.created",
          sortable: true,
        },
        {
          key: "name",
          label: this.$t("COMMON.NAME"),
          sortingKey: "el.name",
          sortable: true,
        },
        {
          key: "typeName",
          label: this.$t("COMMON.TYPE"),
          sortingKey: "el.deviceType.id",
          sortable: true,
        },
        { key: "imei", label: "imei", sortingKey: "el.imei", sortable: true },
        {
          key: "active",
          label: this.$t("COMMON.ACTIVE"),
          formatter: Common.booleanFormatter,
          sortingKey: "el.active",
          sortable: true,
        },
        {
          key: "publicAvailable",
          label: this.$t("DEVICE.PUBLIC"),
          formatter: Common.booleanFormatter,
          sortingKey: "el.publicAvailable",
          sortable: true,
        },
        {
          key: "lastDataReceivedDt",
          label: this.$t("DEVICE.LAST_DATA_RECEIVED"),
          formatter: Common.idObjectFormatter,
          sortingKey: "el.lastDataReceivedDt",
          sortable: true,
        },
      ],

      filterName: "",
      response: {
        data: [],
        totalCount: 1,
      },
      showPerPageArray: [10, 50, 100],

      getList: {
        resource: "/api/device",
        requestType: "GET",
        requestParams: { enrich: true, teamId: ApiService.teamData.value },
      },

      getCommands: {
        resource: "/api/command",
        requestType: "GET",
        requestParams: { enrich: true, calculate: false },
      },

      currentPage: 1, // page numbers indexed from 1
      perPage: 10,

      showTableOverlay: true,
      calculate: false,
      selectedItem: null,

      filterDeviceTypeId: null,
      filterMyDevices: true,
      executeCommandOverlay: false,
      importDataModalOverlay: false,

      importForm: {
        imei: "",
        password: null,
        file: null,
        type: "wialon",
      },
      importTypes: [
        {
          value: "wialon",
          text: "Wialon IPS2.0",
        },
      ],
      commands: [],
      selectedCommand: {
        value: null,
        text: null,
        parameters: [],
      },

      save: {
        resource: "/api/command/save",
        requestType: "POST",
        requestParams: {},
      },

      selectedDevice: { name: null, id: null },
      selectedCatalogue: { name: null, id: null },

      groupTypeOptions: GroupTypeOptions,

      csvDevice: { id: null },
      csvOverlay: false,
      variantParameters: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    itemsTotal() {
      return this.response.totalCount;
    },
    modalCSVDisabled() {
      if (!this.csvDevice.id) return true;
      if (!this.csvDevice.parameters) return true;
      if (!this.csvDevice.startDate) return true;
      if (!this.csvDevice.endDate) return true;
      if (!this.csvDevice.groupType) return true;
      return false;
    },
  },

  watch: {
    async "csvDevice.id"(val) {
      this.csvOverlay = true;
      if (val == null) return;
      const q = "/api/measure/available-parameters";
      let deviceIds = [];
      deviceIds.push(val);
      const requestParams = {
        deviceIds: deviceIds,
        teamId: ApiService.teamData.value,
      };

      const { data } = await this.axios.get(q, { params: requestParams });
      this.variantParameters = data || [];
      this.csvOverlay = false;
    },
  },

  mounted() {
    let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.DEVICES") }]);
    this.$store.dispatch(SET_ACTIONS, [
      {
        label: this.$t("ACTIONS.CREATE_NEW"),
        action: this.onCreateNewAction,
        icon: "fas fa-plus",
      },
      {
        label: this.$t("ACTIONS.EDIT"),
        action: this.onEditAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-edit",
      },
      {
        label: this.$t("ACTIONS.DELETE"),
        action: this.onDeleteAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-trash-alt",
      },
      {
        label: this.$t("ACTIONS.RELOAD"),
        action: this.onReloadAction,
        icon: "fas fa-sync",
      },
      {
        label: this.$t("ACTIONS.IMPORT_DATA"),
        action: this.showImportDataModal,
        icon: "fas fa-file-import",
      },
      {
        label: this.$t("DASHBOARD.WIDGET.EXPORT_CSV"),
        action: this.showExportCSVModal,
        icon: "fas fa-file-export",
      },
      {
        label: this.$t("COMMON.COMMAND"),
        action: this.showCommandModal,
        icon: "fas fa-terminal",
        // disabled: function() {
        //   return that.selectedItem === null || that.showTableOverlay;
        // }
      },
    ]);
  },

  created() {
    this.getList.requestParams.count = this.perPage;
    this.getList.requestParams.calculate = this.calculate;
       this.getList.requestParams.teamId = ApiService.teamData.value;
    this.loadList();
  },

  methods: {
    importData: function (bvModalEvt) {
      bvModalEvt.preventDefault();
      this.importDataModalOverlay = true;

      var bodyFormData = new FormData();
      bodyFormData.set("imei", this.importForm.imei);
      bodyFormData.set("password", this.importForm.password);
      bodyFormData.append("file", this.importForm.file);

      axios({
        method: "post",
        url: "http://178.62.193.219:8081/wialon/measurements",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          this.$bvToast.toast(this.$t("DEVICE.IMPORT_COMPLETED"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
            noAutoHide: true,
          });
          this.$bvModal.hide("modal-import");
        })
        .catch(() => {
          this.$bvToast.toast(this.$t("DEVICE.IMPORT_FAILED"), {
            title: this.$t("COMMON.ERROR"),
            variant: "danger",
            autoHideDelay: 5000,
            noAutoHide: true,
          });
        })
        .finally(() => {
          this.importDataModalOverlay = false;
        });
    },
    showImportDataModal: function () {
      if (this.selectedItem != null) {
        this.importForm.imei = this.selectedItem.imei;
        this.importForm.password = this.selectedItem.password;
      }
      this.$bvModal.show("modal-import");
    },

    showExportCSVModal() {
      if (this.selectedItem != null) {
        this.csvDevice.id = this.selectedItem.id;
        this.$refs.csvDeviceSuggestionProcessor.setText(this.selectedItem.name);
      }
      this.$bvModal.show("modal-export");
    },

    loadList: function () {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = Common.processEntityListResponse(
            this.response,
            response,
            this.currentPage,
            this.perPage,
            this.calculate
          );
          this.currentPage = this.response.noData
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    onPageSelected: function (index) {
      this.getList.requestParams.start = (index - 1) * this.perPage;
      this.loadList();
    },

    onRowsSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];

        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedItem = null;
      }
    },

    onCreateNewAction: function () {
      this.$router.push({ name: "device" });
    },

    onDeleteAction: function () {
      this.$bvModal.show("modal-delete-confirmation");
    },

    onEditAction: function () {
      this.$router.push({
        name: "device",
        params: { id: this.selectedItem.id },
      });
    },

    onReloadAction: function () {
      this.getList.requestParams.start = 0;
      this.currentPage = 1;
      this.loadList();
    },

    onDelete: function () {
      const del = {
        resource: "/api/device/" + this.selectedItem.id + "/delete",
        requestType: "POST",
      };
      this.$store
        .dispatch(API_REQUEST, del)
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.DELETED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });

          // update table
          this.currentPage = 1;
          this.getList.requestParams.start = 0;
          this.loadList();
        })
        .catch(this.onError);
    },

    showPerPageChanged: function (count) {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = count;
      this.perPage = count;

      this.loadList();
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    sortingChanged: function (ctx) {
      this.currentPage = 1;
      this.getList.requestParams.sortDir = ctx.sortDesc ? "desc" : "asc";

      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sortField = field.sortingKey;
      } else {
        this.getList.requestParams.sortField = ctx.sortBy;
      }
      this.getList.requestParams.start = 0;
      this.loadList();
    },

    onFilterSearch: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;

      this.getList.requestParams["name"] =
        this.filterName.length > 0 ? this.filterName : null;
      this.getList.requestParams["deviceTypeId"] = this.filterDeviceTypeId
        ? this.filterDeviceTypeId
        : null;
      this.getList.requestParams["teamId"] = this.filterMyDevices
        ? ApiService.teamData.value
        : null;

      this.loadList();
    },
    onFiltersClear: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = this.perPage;

      delete this.getList.requestParams["name"];
      this.filterName = "";

      delete this.getList.requestParams["deviceTypeId"];
      this.$refs.deviceTypeSuggestionRef.clearValue();

      this.loadList();
    },

    showCommandModal: function () {
      this.commands = [];
      this.selectedCommand = { value: null, text: null, parameters: [] };
      this.$refs.sendCommandCatalogueSuggestionProcessor.clearValue();
      if (this.selectedItem != null) {
        this.setDevice(
          this.selectedItem.id,
          this.selectedItem.name,
          this.selectedItem.typeId
        );
      } else {
        this.$refs.sendCommandDeviceSuggestionProcessor.clearValue();
      }

      this.$bvModal.show("modal-command");
    },

    setDevice: function (id, name, deviceTypeId) {
      this.executeCommandOverlay = true;
      this.selectedDevice.id = id;
      this.selectedDevice.name = name;
      this.selectedCommand = { value: null, text: null, parameters: [] };
      this.getCommands.requestParams.deviceTypeId = deviceTypeId;
      this.selectedCatalogue.id = null;
      this.selectedCatalogue.name = null;
      this.commands = [];
      this.$store
        .dispatch(API_REQUEST, this.getCommands)
        .then((response) => {
          if (response.data.length > 0) {
            for (const value of response.data) {
              this.commands.push({
                value: value.id,
                text: value.name,
                parameters: value.commandProperties,
              });
            }
            this.$refs.sendCommandDeviceSuggestionProcessor.setText(
              this.selectedDevice.name
            );
          } else {
            this.$bvToast.toast(this.$t("DEVICE.COMMANDS_NOT_FOUND"), {
              title: this.$t("COMMON.WARNING"),
              variant: "warning",
              autoHideDelay: 5000,
            });
          }
        })
        .catch(this.onError)
        .finally(() => {
          this.executeCommandOverlay = false;
        });
    },

    executeCommand: function () {
      const request = this.save;

      let properties = {};
      if (this.selectedCommand.parameters != null) {
        for (const val of this.selectedCommand.parameters) {
          properties[val.code] = val.defaultValue;
        }
      }
      request.dto = this.selectedCommand;
      request.dto.properties = properties;
      request.dto.commandId = this.selectedCommand.value;
      request.dto.deviceId = this.selectedDevice.id;
      request.dto.catalogueId = this.selectedCatalogue.id;
      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError);
      this.commands = [];
      this.selectedCommand = { text: null, value: null, parameters: [] };
      this.$bvModal.show("modal-command");
    },

    getCSV(bvModalEvt) {
      bvModalEvt.preventDefault();
      const q = "/api/measure/csv";
      const params = {
        deviceIds: [this.csvDevice.id],
        teamId: ApiService.teamData.value,
        parameterIds: this.csvDevice.parameters,
        startDate: this.csvDevice.startDate,
        endDate: this.csvDevice.endDate,
      };

      console.log(params);

      this.csvOverlay = true;
      axios
        .get(q, { params: params })
        .then((data) => {
          const blob = new Blob([data.data], { type: "text/csv" });
          saveAs(blob, "export.csv");
        })
        .catch(() => {
          this.$bvToast.toast(this.$t("DEVICE.EXPORT_FAILED"), {
            title: this.$t("COMMON.ERROR"),
            variant: "danger",
            autoHideDelay: 5000,
            noAutoHide: true,
          });
        })
        .finally(() => {
          this.csvOverlay = false;
        });
    },

    onCommandSelected: function () {
      let item = this.selectedCommand;
      this.selectedCommand = { text: null, value: null, parameters: [] };

      for (const com of this.commands) {
        if (item.value == com.value) {
          this.selectedCommand = {
            text: com.text,
            value: com.value,
            parameters: com.parameters,
          };
          break;
        }
      }
    },

    onCatalogueSelected: function (val) {
      let com = JSON.parse(val.tags.commands);
      this.commands = [];
      if (com != null && com.length > 0) {
        this.selectedCommand = { value: null, text: null, parameters: [] };
        for (const value of com) {
          this.commands.push({
            value: value.id,
            text: value.name,
            parameters: value.commandProperties,
          });
        }
        this.selectedDevice.id = null;
        this.selectedDevice.name = null;
        this.selectedCatalogue.id = val.id;
      } else {
        this.$bvToast.toast(this.$t("DEVICE.COMMANDS_NOT_FOUND"), {
          title: this.$t("COMMON.WARNING"),
          variant: "warning",
          autoHideDelay: 5000,
        });
      }
    },
  },
};
</script>
